
/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&display=swap');

body {
  position: relative;
  margin: 0;
  font-family: 'Cabin', sans-serif;
  font-size: 12px;
  color: #ffffff;
  background-color: #f0f0f0;
}

/* Header and Hero Section */
.header {
  background-color: #03B6C8;
  background-image: url('./img/background-image.jpg');
  background-size: cover;
  background-position: top left;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.container {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo-container {
  display: block;
  margin: 0 auto;
}

.logo {
  width: 150px;
  margin: 0 auto;
}

.hero-content {
  max-width: 600px;
  margin: 0 auto;
}

.main-heading {
  font-size: 3em;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 1.4;
  text-shadow: 1px 1px rgba(0,0,0,0.2);
}

.subtext {
  font-size: 1.25em;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 1.5;
  text-shadow: 1px 1px rgba(0,0,0,0.2);
}

.cta-button {
  width: 247px;
  height: 44px;
  background-color: #ff7f50;
  color: #ffffff;
  font-size: 1.17em;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  letter-spacing: 0.5px;
  line-height: 0.8;
}

.cta-button:hover {
  background-color: #ff5722;
}

/* Form Section */
.form-section {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: none;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.5);
  opacity: 0;
  transition: 0.5s;
}

.form-section.isVisible {
  display: flex;
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.itinerary-form {
  position: relative;
  max-width: 500px;
  width: 100%;
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 2;
  box-sizing: border-box;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 1.1em;
  font-weight: 700;
  margin-bottom: 8px;
  color: #333333;
}

.input-field {
  width: 100%;
  padding: 10px;
  color: #333333;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  height: 44px;
  padding: 0;
  background-color: #ff7f50;
  color: #ffffff;
  font-size: 1.17em;
  border: none;
  border-radius: 22px;
  letter-spacing: 0.5px;
  line-height: 1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #ff5722;
}

/* Modal */
.modal {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: none;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.5);
  opacity: 0;
  transition: 0.5s;
}

.modal.isVisible {
  display: flex;
  opacity: 1;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.modal-content {
  position: relative;
  width: 720px;
  max-width: 90%;
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 2;
  color: #555;
  max-height: 80%;
  overflow: auto;
  line-height: 1.5;
  font-size: 12px;
  box-sizing: border-box;
}

@keyframes fadeInOut {
  0% {opacity: 100%;}
  50% {opacity: 20%;}
  100% {opacity: 100%;}
}

.loading-text {
  text-align: center;
  color: #777;
  font-size: 14px;
  animation: fadeInOut 3s infinite;
}

/* Footer and Loading Text */
.footer {
  text-align: center;
  padding: 0;
  background-color: #333333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-heading {
    font-size: 2.5em;
  }

  .cta-button {
    font-size: 1em;
    padding: 12px 25px;
  }

  .itinerary-form {
    padding: 20px;
  }
}